<template>
  <el-date-picker
    v-model='val'
    @change='updateField'
    type='date'
    @focus='$emit("focus")'
    placeholder='Pick a day'>
  </el-date-picker>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'TableTypeCustomFieldDate',
  props: ['row', 'rowIndex', 'colKey'],
  data () {
    return {
      val: this.row[this.colKey] || ''
    }
  },
  methods: {
    updateField () {
      let newValue = dayjs(this.val).isValid() ? dayjs(this.val).format('YYYY-MM-DD') : ''
      this.$emit('update-value-json', this.rowIndex, this.colKey, newValue)
      this.$emit('blur')
    },
  }
}
</script>
